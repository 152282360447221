@use 'variables' as var;

$thumb-size: 0.625rem;
$ripple-size: 1.25rem;

.mat-slide-toggle {
  .mat-slide-toggle-bar {
    height: 1.5rem;
    width: 2.5rem;
    border-radius: 0.75rem;
    background-color: transparent;
    border: 1px solid var.$light-grey;
  }

  .mat-slide-toggle-thumb-container {
    width: $thumb-size;
    height: $thumb-size;
    top: 0.375rem;
    left: 0.3125rem;

    .mat-slide-toggle-thumb {
      width: $thumb-size;
      height: $thumb-size;
      box-shadow: none;
      background-color: var.$grey;
    }

    .mat-slide-toggle-ripple {
      height: $ripple-size;
      width: $ripple-size;
      top: calc(50% - (#{$ripple-size} / 2));
      left: calc(50% - (#{$ripple-size} / 2));
      border-radius: 50%;
    }
  }

  &.mat-checked {
    .mat-slide-toggle-bar {
      background-color: var.$theme-primary;
      border-color: var.$theme-primary;
    }

    .mat-slide-toggle-thumb-container {
      transform: translate3d(1.0625rem, 0, 0);

      .mat-slide-toggle-thumb {
        background-color: white;
      }
    }
  }
}
