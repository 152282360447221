@use '@wizbii/backoffice-styles/modules/bo-theming' as boTheming;
@use '@wizbii/backoffice-styles/modules/bo-navmenu' as boNavMenu;

// Define the palette using : http://mcg.mbitson.com/
$md-purple: (
  50: #ebeaff,
  100: #cdcbfe,
  200: #aba8fd,
  300: #8985fc,
  400: #706afc,
  500: #5750fb,
  600: #4f49fa,
  700: #4640fa,
  800: #3c37f9,
  900: #2c27f8,
  A100: #fff,
  A200: #fff,
  A400: #cfceff,
  A700: #b6b4ff,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #000,
    500: #000,
    600: #000,
    700: #000,
    800: #000,
    900: #000,
    A100: #000,
    A200: #000,
    A400: #000,
    A700: #000,
  ),
);
$md-dark-blue: (
  50: #e0e0e5,
  100: #b3b3bf,
  200: #808094,
  300: #4d4d69,
  400: #262648,
  500: #000028,
  600: #000024,
  700: #00001e,
  800: #000018,
  900: #00000f,
  A100: #5252ff,
  A200: #1f1fff,
  A400: #0000eb,
  A700: #0000d1,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #fff,
    400: #fff,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
    A100: #fff,
    A200: #fff,
    A400: #fff,
    A700: #fff,
  ),
);
$md-invalid-red: (
  50: #ffebec,
  100: #ffcecf,
  200: #ffaeb0,
  300: #ff8d90,
  400: #ff7478,
  500: #ff5c60,
  600: #ff5458,
  700: #ff4a4e,
  800: #ff4144,
  900: #ff3033,
  A100: white,
  A200: white,
  A400: #ffdbdc,
  A700: #ffc2c3,
  contrast: (
    50: black,
    100: black,
    200: black,
    300: black,
    400: black,
    500: black,
    600: black,
    700: black,
    800: white,
    900: white,
    A100: black,
    A200: black,
    A400: black,
    A700: black,
  ),
);

@include boTheming.boTheming($md-purple, $md-dark-blue, $md-invalid-red);
@include boNavMenu.boNavMenu(#5750fb, #ffffff, #4841e9, #ffffff, #000028, #ffffff);
