@use 'variables' as var;

.mat-datepicker-popup .mat-datepicker-content {
  box-shadow: 0 0.25rem 0.5rem var.$shadow-color;
  border-radius: 10px;

  .mat-calendar {
    width: 19.5rem;

    &-header {
      padding: 0.31rem 1.25rem 0;
    }

    &-content {
      padding: 0 1.25rem 1.25rem;
    }

    &-body {
      &-selected {
        background-color: var.$theme-highlight;
        color: white;
      }

      &-cell-content {
        font-weight: 500;
        font-size: 0.75rem;
      }
    }

    &-next-button,
    &-previous-button {
      color: var.$theme-primary;
    }

    &-abbr {
      text-decoration: none;
      color: black;
      text-transform: capitalize;
      font-weight: 500;
      font-size: 0.75rem;
    }
  }

  .mat-button-wrapper {
    text-transform: lowercase;
    color: var.$theme-primary;
  }
}
