@use 'variables' as var;

.chart-list {
  display: flex;
  flex-direction: column;
  row-gap: 2.5rem;
  padding: 0;

  li {
    list-style: none;
  }

  &__members {
    display: grid;
    grid-template-columns: repeat(auto-fit, var.$card-width);
    justify-items: center;
    justify-content: center;
    gap: 1.5rem;
    list-style: none;
    max-width: 53rem;
    margin: 0 auto;
    padding: 0;
  }
}

@media (max-width: 88rem) {
  .chart-list__members {
    max-width: unset;
    margin: unset;
  }
}
