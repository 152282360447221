@use 'sass:color';
@use '@wizbii/styles/modules/wzb-utils';
@use 'themes';
@use '@wizbii/styles/modules/wzb-breakpoints';
@use '@wizbii/styles/modules/wzb-heading';
@use '@wizbii/styles/modules/wzb-variables' as wzbVar;
@use './custom-material';
@use './block-background.scss';
@use './chart.scss';
@use './info-tag.scss';
@use 'variables' as var;
@use '@angular/cdk/text-field' as cdkTextField;

// Add normalize
@import '~normalize.css';

* {
  box-sizing: border-box;
}

html {
  overflow: visible !important;
  scroll-behavior: smooth;
  // stylelint-disable-next-line font-family-name-quotes
  font-family: wzbVar.$font-family-sans-serif;
  line-height: 1.625;
  text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
  padding-right: 0 !important;
  overflow-x: visible !important; // setting `overflow-y` has the unwanted effect of setting `overflow-x` to `auto`...
  overflow-y: scroll !important; // prevent jumps when switching between long and short pages
  color: wzbVar.$wizbii-black;
}

h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.5;
  margin: 0;
}

.h3-title {
  padding: 1.5rem 0;
}

textarea {
  font-family: inherit;
  resize: vertical;
}

pre {
  overflow: auto;
}

label,
output {
  display: inline-block;
}

img,
svg {
  vertical-align: middle;
}

svg {
  display: block;
  border: 0;
  fill: currentcolor;

  &:not(:root) {
    overflow: hidden;
  }
}

a {
  color: inherit;
  text-decoration-skip-ink: auto;
  text-decoration: none;

  &:not([href]) {
    text-decoration: none !important;
  }
}

h1,
.page-title {
  height: 2rem;
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.33;
  color: var.$theme-primary;
}

button,
input,
optgroup,
select,
textarea {
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}

input {
  @include cdkTextField.text-field-autofill-color(color.adjust(var.$theme-primary, $alpha: -0.8), wzbVar.$wizbii-black);

  // stylelint-disable-next-line selector-no-qualifying-type
  &[type='number'] {
    appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
}

button,
summary {
  cursor: pointer;
}

strong {
  font-weight: 600;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

[hidden] {
  display: none !important;
}

[tabindex='-1']:focus {
  outline: 0 !important;
}

@media (prefers-reduced-motion) {
  * {
    transition: none !important;
    animation: none !important;
  }
}

.lock {
  overflow: hidden;
}

.hover-on {
  a:hover {
    text-decoration: underline;
  }
}

.wrapper {
  background-color: white;
  border-radius: 0.625rem;
  padding: 0 1.5rem 1.5rem;
}

.connect-as {
  background-color: white;
  color: var.$theme-primary;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  border: 0.0625rem solid var.$theme-primary;
  border-radius: 0.625rem;
  align-self: start;
  grid-column: 2;
  justify-self: end;
  margin-top: 1.0625rem;
  padding: 0.625rem 1.25rem;
  box-shadow: none;
}

.submit-btn {
  color: white;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  grid-column: 2;
  justify-self: end;
  padding: 0.625rem 1.25rem;
  box-shadow: none !important;
}

.hoverRowList {
  height: 3.4375rem;
  transition: transform 150ms ease-out, box-shadow 150ms ease-out, border-color 150ms ease-out;
  box-shadow: 0 0 0 rgb(0 0 0 / 6%);

  &:hover {
    box-shadow: 0 0.3125rem 1.25rem rgb(0 0 0 / 6%);
    transform: translateY(-0.1875rem);
    border-color: transparent;
    text-decoration: none;
  }
}

.noneRow {
  cursor: default !important;
  border: none !important;
  max-height: 1.5rem;
}

.focus-off *:focus {
  outline: none;
}

.ck-content {
  height: 30rem;
  box-shadow: 0 0 0.3125rem #a2a1a1 inset;
}

.wrapper-button {
  display: inline-grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.75rem;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 2;
}

.btn-copy-id {
  opacity: 0;
  transition: opacity 0s 2.5s;
  border: 0.0625rem solid wzbVar.$grey-5;
  border-radius: 0.5rem;
  position: relative;
  height: 1.625rem;
  width: 5.1875rem;

  &__active,
  &__inactive {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  &--check {
    color: var.$theme-primary;
  }
}

.wrapper-button:hover .btn-copy-id {
  opacity: 1;
  transition: opacity 0s;
}
