@use 'variables' as var;

.search-employee .mat-form-field-appearance-outline .mat-form-field {
  &-prefix {
    opacity: 1;
  }

  &-label {
    margin-top: 0;
  }
}

.search-form {
  display: flex;
  height: 3rem;
  justify-content: space-between;
  gap: 1rem;

  &__field {
    border-radius: var.$search-form-field-border-radius;
    background-color: white;
    font-size: 0.8125rem;
    box-shadow: 0 0.125rem 0.25rem var.$shadow-color;
  }

  &__query {
    flex-grow: 2;
  }

  &__query-icon {
    position: relative;
    color: var.$theme-primary;
    font-weight: bold;
    top: 0.35rem;
  }

  .mat-form-field-appearance-outline {
    .mat-form-field {
      &-prefix {
        opacity: 1;
      }

      &-label {
        color: black;
      }

      &-outline-start {
        border-radius: var.$search-form-field-border-radius 0 0 var.$search-form-field-border-radius;
      }

      &-outline-end {
        border-radius: 0 var.$search-form-field-border-radius var.$search-form-field-border-radius 0;
      }
    }

    &:not(.mat-focused) {
      /* stylelint-disable selector-max-specificity */

      .mat-form-field {
        &-outline-start,
        &-outline-end,
        &-outline-gap {
          border: unset;
        }

        &-empty {
          top: 2.1em;
        }
      }
      /* stylelint-enable selector-max-specificity */
    }

    .mat-select-arrow {
      color: var.$theme-primary;
      border-right-width: 2.4px;
      border-top-width: 2.4px;
    }
  }
}
