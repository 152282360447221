@use 'variables' as var;
@use 'sass:color';

.wzb-bo-navmenu {
  background-color: var.$theme-primary;

  &__link--selected .navmenu__link__icon,
  .navmenu__link__icon:hover {
    background-color: color.adjust(var.$theme-primary, $lightness: -10%) !important;
  }
}
