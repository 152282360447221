@use 'sass:color';

$theme-primary: #4b45b2;
$theme-secondary: #757491;
$theme-accent: #1c1c28;
$theme-light: #e8e1fc;
$theme-dark: color.adjust($theme-primary, $lightness: -10%);
$theme-highlight: #0062ff;
$success: #21d3a2;
$warning: #f5a623;
$danger: #fc5a5a;
$shadow-color: rgb(96 97 112 / 16%);
$light-yellow: #fce9c5;
$light-grey: #e2e7f0;
$light-red: #ff7f89;
$grey: #92929d;

/* stylelint-disable-next-line unit-disallowed-list */
$search-form-field-border-radius: 14px;
$card-width: 11.25rem;
