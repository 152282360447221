@use 'variables' as var;

%panel {
  border-radius: 16px;
  box-shadow: 0 0.5rem 1rem var.$shadow-color;
  padding-top: 0.2rem;

  .mat-option-text {
    text-align: center;
  }

  .mat-option.mat-focus-indicator {
    &.mat-active,
    &:hover:not(.mat-option-disabled) {
      .mat-option-text {
        background-color: var.$theme-light;
        border-radius: 10px;
        line-height: 1;
      }
    }
  }
}

.wzb-panel.mat-select-panel,
.mat-autocomplete-panel.mat-autocomplete-visible {
  @extend %panel;
}

.mat-select-panel-wrap .mat-select-panel,
.mat-autocomplete-panel.mat-autocomplete-visible {
  @extend %panel;

  .mat-option {
    padding: 0 0.3125rem;

    &.mat-active {
      background-color: unset;
    }

    &:hover {
      background-color: unset;
    }

    .mat-option-text {
      padding: 0.6875rem;
      line-height: 1.62;
      background-color: unset;
    }
  }

  &.decenteredPanel {
    margin-top: 2.25rem;
  }
}

.mat-autocomplete-panel.mat-autocomplete-visible {
  .mat-option.mat-focus-indicator {
    margin-left: unset;
    margin-right: unset;
    padding: 0 0.3125rem !important;
    border-radius: unset;
  }
}
