@use 'variables' as var;

.form-container__nav-bar .wzb-navbar {
  background-color: white;
  border-radius: 100px !important;
  box-shadow: 0 0.25rem 0.25rem rgb(96 97 112 / 10%);

  &__link {
    color: var.$theme-secondary;
    border-radius: 100px !important;
    min-width: unset !important;
    padding: 0.625rem 1rem !important;
  }

  &__active-link {
    background-color: var.$theme-highlight;
    color: white;
    font-weight: normal !important;
  }
}
