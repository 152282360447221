@use 'variables' as var;

.mat-table {
  .mat-header-row {
    background-color: var.$theme-light;

    .mat-header-cell {
      color: var.$theme-primary;
      font-size: 0.75rem;
    }
  }

  .bo-table__row.mat-row {
    min-height: 3.75rem;

    &--unclickable {
      pointer-events: none;
      opacity: 0.6;

      .edit-icon {
        display: none;
      }
    }
  }

  .mat-cell {
    color: var.$theme-secondary;
  }
}

@mixin select-row() {
  %selected-row {
    border: 1px solid var.$theme-primary;
    opacity: 1 !important;
  }

  $index: 0;

  .selected-row__new .mat-row:first-of-type {
    @extend %selected-row;

    .edit-icon {
      display: none;
    }
  }

  @while $index < 10 {
    .selected-row__#{$index} .mat-row:nth-child(#{$index * 2 + 2}) {
      @extend %selected-row;

      .edit-icon {
        display: block;
      }
    }

    $index: $index + 1;
  }
}

@include select-row;
