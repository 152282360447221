@use 'variables' as var;

.info-tag {
  background-color: var.$theme-light;
  color: var.$theme-primary;
  white-space: pre;
  padding: 0.375rem 0.75rem;
  border-radius: 20px;
  font-size: 0.563rem;

  em {
    font-style: normal;
    font-weight: 800;
  }
}
