.wzb-bo-sidemenu {
  .title__texts {
    font-weight: 600;
  }

  .title__label {
    font-weight: normal;
  }

  .item::before {
    background-color: black;
    border-radius: 50%;
    content: '';
    height: 0.375rem;
    margin-left: 0.8125rem;
    margin-right: 1.4375rem;
    width: 0.375rem;
  }
}
