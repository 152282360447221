@use 'variables' as var;

.default-form {
  label,
  &__label {
    margin-bottom: 0.5rem;
    font-weight: 500;
    font-size: 0.875rem;
  }

  .mat-form-field {
    display: block;

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-error {
      margin: 0 0.4rem;
    }
  }

  /* stylelint-disable-next-line selector-max-specificity */
  .mat-form-field-appearance-outline:not(.mat-focused) .mat-form-field-flex:hover .mat-form-field-outline-thick {
    color: rgb(0 0 0 / 12%);
  }

  &__birthday-field .mat-form-field-suffix {
    align-self: center;
  }

  .wzb-file-picker {
    &__employee-picture img {
      border-radius: 100px;
      aspect-ratio: 1/1;
      object-fit: cover;
    }
  }

  .mat-checkbox-frame {
    border: 1px solid #d4d3d2;
    border-radius: 4px;
  }
}
