@use 'utils' as utils;

@mixin sized-mat-icon-button($name, $button-size, $icon-size) {
  .mat-icon-button-size-#{$name}.mat-icon-button {
    @include utils.center;

    width: $button-size;
    height: $button-size;
    line-height: $button-size;

    .mat-button-wrapper {
      @include utils.center;

      .mat-icon {
        width: $icon-size;
        height: $icon-size;
      }
    }
  }
}

@include sized-mat-icon-button('32', 2rem, 1rem);
@include sized-mat-icon-button('40-chevron', 2.5rem, 2rem);
