.query-search-form .mat-form-field {
  .mat-form-field-infix {
    display: flex;
    height: 3rem;
    padding: 0.5rem 0 0;
    border: 0;
  }

  input {
    font-size: 0.875rem;
  }
}
