@use 'variables' as var;

.confirm-modal .mat-dialog-container {
  padding: 2rem 2.5rem;
  width: 21rem;
}

.wiz-dialog-container .mat-dialog-container {
  border-radius: 0;
  padding: 0;
  width: 65rem;
  min-height: 33.1rem;
}

.wzb-common-dialog .mat-dialog-container {
  border-radius: 10px;

  .mat-dialog-close {
    color: var.$theme-secondary;
    width: 1rem;
    line-height: unset;
    height: 1rem;

    .mat-icon {
      width: auto;
      height: auto;
      vertical-align: unset;
    }

    &:hover {
      background-color: var.$theme-light;
    }
  }
}
